<template>
    <div class="channels">
    <div class="channels_logo">
    <router-link to="/">
        <img src="/assets/images/global/campfire-logo.png" alt="">
    </router-link>
    </div>
    <span class="separator"></span>
        <div class="channelList">
          <router-link v-if="serverList" v-for="server in serverList" :to="`/channel/${server.campfireID}/${server.welcomeChannel}`">
             <img v-if="!server.icon" class="server-icon" src="/assets/images/global/campfire-logo-1.png" alt="">
             <img v-if="server.icon" class="server-icon" :src="server.icon" alt="">
             <span class="server-name">{{ server.name }}</span>
          </router-link>
          <router-link class='invite' to="/invite">
                <span class="server-icon icon-plus"></span>
                <span class="server-name">Invite or create server</span>
          </router-link>
        </div>
    </div>
</template>

<script>
import { getFirestore, doc, getDocs, collection, query } from 'firebase/firestore'
import { ref, onMounted } from 'vue'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import ChannelService from '../composables/ChannelComposable.js'
import ServerService from '../composables/ServerComposable.js'
import UserService from '../composables/UserComposable.js'
export default {
    setup() {
        const serverList = ref([])
        const db = getFirestore()
        const auth = getAuth()
        const actualUser = ref()
        
        const { getAllChannels } = ChannelService()
        const { getAllServers} = ServerService()
        const { getActualUser } = UserService()
         
        onMounted(async () => {
                  
            auth.onAuthStateChanged(async function(user) {
                if(user) {
                    actualUser.value = await getActualUser(db, user.uid)
                }
             })
             let servers = await getAllServers(db);
            for (const server of servers) {
                if (server.members.includes(actualUser.value.campfireID)) {
                    serverList.value.push(server)
                }
            }
        })

        return { serverList }
    }
}
</script>