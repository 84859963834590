import { getDoc, doc, getDocs, collection, where, query } from 'firebase/firestore';

const ServerService = () => {

    const getOneServer = async (db, data) => {
        const docRef = doc(db, "servers", data);
        const docSnap = await getDoc(docRef);
    
        if(docSnap.exists()) {
            //console.log("one channel: ", docSnap.data());
        } else {
            console.log("No such document!");
        }
    
        return docSnap.data();
    }

    const getAllServers = async (db) => {
        const querySnapshot = await getDocs(collection(db, "servers"))
        let servers = [];
        querySnapshot.forEach((doc) => {
            servers.push(doc.data());
        })
        //console.log("all servers: ", servers)
        return servers;
    }

    const getServersWhere = async (db, at, value) => {
        const q = query(collection(db, "servers"), where(at, "==", value));
        const querySnapshot = await getDocs(q);
        let serversWhere = [];
        querySnapshot.forEach((doc) => {
            serversWhere.push(doc.data());
        })
        //console.log(`servers where (${at} == ${value}) :`, serversWhere)
        return serversWhere;
    }


    return {
        getOneServer,
        getAllServers,
        getServersWhere

    }
}
    
export default ServerService 