<template>
    <div class="maintenance">
        <CampfireLogo />
        <p>The campfire needs more wood.. Come back later</p>
    </div>
</template>

<script>
import CampfireLogo from '../components/CampfireLogo'
export default {
    components: {
        CampfireLogo
    }
}
</script>