<template>
        <div class="login">
            <div class="login_container">
                <CampfireLogo />
                <form @submit.prevent="signIn">       
                    <label class="" for="username">Email</label>
                    <input v-model="email" class="" required id="username" type="email">
                    <label class="" for="password">Password</label>
                    <input v-model="password" class="" required id="password" type="password">
                    <!-- <p class="text-red-500 text-xs italic">Please choose a password.</p> -->
                    <button class="" type="submit">Login</button>
                    <!-- <a class="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="#">
                        Forgot Password?
                    </a> -->
                </form>
                <p class="errorMessage" v-if="errMsg">{{ errMsg }}</p>
                <router-link to="/register">Not registered ? Click here</router-link>
            </div>
        </div>  
</template>

<script>     
import { ref } from 'vue'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { useRouter } from 'vue-router' // import router
import CampfireLogo from '../components/CampfireLogo'

export default {
    components: {
        CampfireLogo
    },
    setup() {
        const auth = getAuth()
        const email = ref('')
        const password = ref('')
        const errMsg = ref() // ERROR MESSAGE
        const router = useRouter() // get a reference to our vue router
        const signIn = () => { // we also renamed this method
          signInWithEmailAndPassword(auth,email.value, password.value) // THIS LINE CHANGED
            .then((data) => {
             // console.log('Successfully logged in!');
              router.push('/') // redirect to the feed
            })
            .catch(error => {
              switch (error.code) {
                case 'auth/invalid-email':
                    errMsg.value = 'Invalid email'
                    break
                case 'auth/user-not-found':
                    errMsg.value = 'No account with that email was found'
                    break
                case 'auth/wrong-password':
                    errMsg.value = 'Incorrect password'
                    break
                default:
                    errMsg.value = 'Email or password was incorrect'
                    break
              }
            });
        }

        return { email, password, errMsg, signIn }
    }   
}
</script>