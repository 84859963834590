<template>
    <div class="channelInfo">
        Friends
    </div>
    <div class="friendPage">        
        <PrivateMessageList v-if="friendList.length > 0" />
        <div class="friendList">
            <div class="friendRequests" v-if="friendRequests.length > 0">
                <h3>Requests</h3>
                <div class="friendRequest_list">
                    <div class="friendRequest_list-element" v-for="data in friendRequestUser">
                        <div class="friendRequest_list-element_left"> 
                            <div :class="data.status" class="friendRequest_list-element_pfp" :style="{
                            backgroundImage: 'url(' + data.profilePicture + ')',
                            backgroundRepeat: 'no-repat',
                            backgroundSize: 'cover',
                            backgroundBlendMode: 'multiply',
                            backgroundPosition: 'center'
                            }"></div>

                            <div class="friendRequest_list-element_infos">
                                <p class="username">{{data.name}}</p>
                                <p class="campfireID">Campfire-ID : {{data.campfireID}}</p>
                            </div>
                        </div>
                        <div class="friendRequest_list-element_right">
                            <div class="icon-checkmark add" @click="AcceptFriendRequest(data.campfireID)">
                                
                            </div>    
                            <div class="icon-cross remove" @click="DenyFriendRequest(data.campfireID)">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="friendRequests" v-if="friendList.length > 0">
                <h3 v-if="friendRequests.length > 0">Friends</h3>
                <div class="friendRequest_list">
                    <div class="friendRequest_list-element" v-if="friendList" v-for="data in friendListUI">
                        <div class="friendRequest_list-element_left"> 
                            <div :class="data.status" class="friendRequest_list-element_pfp" :style="{
                            backgroundImage: 'url(' + data.profilePicture + ')',
                            backgroundRepeat: 'no-repat',
                            backgroundSize: 'cover',
                            backgroundBlendMode: 'multiply',
                            backgroundPosition: 'center'
                            }"></div>

                            <div class="friendRequest_list-element_infos">
                                <p class="username">{{data.name}}</p>
                                <p class="campfireID">Campfire-ID : {{data.campfireID}}</p>
                            </div>
                        </div>
                        <div class="friendRequest_list-element_right">
                            <div class="icon-bubbles add" @click="openDM(data.campfireID)">
                                
                            </div>    
                            <div class="icon-user-minus remove" @click="deleteFriend(data.campfireID)">
                                
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        Nothing to see here..
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from 'vue'
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { setDoc, getFirestore, updateDoc, doc, where, collection, query, getDocs } from 'firebase/firestore';
import { useRouter } from 'vue-router';
import PrivateMessageList from "../components/PrivateMessageList"
import UserService from '../composables/UserComposable'
import ChannelService from '../composables/ChannelComposable'

export default {
    components: {
        PrivateMessageList
    },
    setup() {

        const { getOneUser, getAllUsers, getUsersWhere, getActualUser, AddToFriendList, RemoveFromFriendList, AddToFriendReq, RemoveFromFriendReq } = UserService();
        const { getChannelsWhere, getAllChannels} = ChannelService();
        let auth = getAuth()
        const db = getFirestore()
        const router = useRouter()

        const friendRequests = ref([])
        let actualUser;

        const friendRequestUser = ref([])
        const friendList = ref([])
        const friendListUI = ref([])
        const friendInfos = ref()

        const instance = getCurrentInstance();
    

        const AcceptFriendRequest = async (id) => {
            clear()
            await AddToFriendList(db, actualUser.campfireID, id)
            await RemoveFromFriendReq(db, actualUser.campfireID, id)
            await AddToFriendList(db, id, actualUser.campfireID) 
            retrieveData()
        }

        const DenyFriendRequest = async (id) => {
            await RemoveFromFriendReq(db, actualUser.campfireID, id)
            retrieveData()
          
        }

        const DM_UUID = () => { // Public Domain/MIT
            let d = new Date().getTime();//Timestamp
            let d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
            return '40xxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                let r = Math.random() * 16;//random number between 0 and 16
                if (d > 0) {//Use timestamp until depleted
                    r = (d + r) % 16 | 0;
                    d = Math.floor(d / 16);
                } else {//Use microseconds since page-load if supported
                    r = (d2 + r) % 16 | 0;
                    d2 = Math.floor(d2 / 16);
                }
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
        }

        const createChannel = (id) => {
            let campfireID = DM_UUID()
            let members = [];
            members.push(id);
            members.push(actualUser.campfireID)
            setDoc(doc(db, "channels", campfireID), {
                campfireID: campfireID,
                name: '',
                members: members,
                description: ''
            })
            router.push('/channel/' + campfireID) 
        }

        const openDM = async (id) => {
            let allChannels = await getAllChannels(db)
            let test;
            let test2;
            let channelId;

            for (const channel of allChannels) {
                if (channel.members && channel.members.length > 0) {
                    test = channel.members.findIndex(x => x === id); //target person find
                    test2 = channel.members.findIndex(y => y === actualUser.campfireID); //current user find
                     if (test >= 0 && test2 >= 0){
                        channelId = channel.campfireID;
                        router.push('/channel/'+ channelId)
                    return;
                    } 
                }
            }

            if (test >= 0 && test2 >= 0){
                router.push('/channel/'+ channelId)
                return;
            } else {
                createChannel(id)
                return;
            } 
        }
           

        const clear = () => {
            friendRequests.value = []
            friendRequestUser.value = []
            friendList.value = []
            friendListUI.value = []
        }


        
        const retrieveData = async () => { 
            friendRequests.value = []
            friendRequestUser.value = []
            friendList.value = []
            friendListUI.value = []
            actualUser = await getActualUser(db, actualUser.uid)            
            friendRequests.value = actualUser.friendRequests;
            friendList.value = actualUser.friendList;  
            for (let i = 0; i < friendRequests.value.length; i++) {
                let friendRequestInfos = await getUsersWhere(db, 'campfireID', friendRequests.value[i])
                friendRequestUser.value.push(friendRequestInfos[0])
            }
            for (let j = 0; j < friendList.value.length; j++) {
                let friendInfos = await getUsersWhere(db, 'campfireID', friendList.value[j])
                friendListUI.value.push(friendInfos[0])
            }
           
        }


        const deleteFriend = async (id) => {
            await RemoveFromFriendList(db, actualUser.campfireID, id)
            await RemoveFromFriendList(db, id, actualUser.campfireID)
            retrieveData()
            
        }

        onMounted(() => {
            auth.onAuthStateChanged(async function(user) {
                if(user) {
                    actualUser = await getActualUser(db, user.uid)
                    retrieveData()
                }
             })
        })

        return { openDM, friendInfos, deleteFriend, friendListUI, friendList, friendRequests, friendRequestUser, AcceptFriendRequest, DenyFriendRequest }
    }

}
</script>