import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Markdown from 'vue3-markdown-it';


import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyB43CxUr7uKqQBPTQvmlmC15QXPv-B9HpE",
    authDomain: "campfire-d1e6c.firebaseapp.com",
    projectId: "campfire-d1e6c",
    storageBucket: "campfire-d1e6c.appspot.com",
    messagingSenderId: "110364069672",
    appId: "1:110364069672:web:0e0e80e09faff8e59a0a1d"
  };

  initializeApp(firebaseConfig)
let app = createApp(App)
app.use(Markdown)
app.use(router)
app.mount('#app')

