<template>
    <div class="user-update">
        <div class="user-update_banner">
            <div class="user-update_banner_pfp" :style="{
            backgroundImage: 'url(' + profileImage +')',
            backgroundRepeat: 'no-repat',
            backgroundSize: 'cover',
            backgroundBlendMode: 'multiply',
            backgroundPosition: 'center'
            }"></div>
            <div class="user-update_banner_infos">
                <p class="user-update_banner_username">{{ username }}</p>     
                <p class="user-update_banner_campfireID">Campfire-ID : {{ campfireID }}</p> 
            </div>
            <div @click="signOut()" class="user-update_banner_disconnect">
                <span>Disconnect</span>
                <div class="icon-exit"></div>
            </div>
            <div @click="openSettings" class="user-update_banner_edit">
                <span v-if="!EditProfile">Edit Profile</span>
                <span v-if="EditProfile">Close</span>
                <div v-if="!EditProfile" class="icon-pencil"></div>
                <div v-if="EditProfile" class="icon-cross"></div>

            </div>
        </div>
        <div v-if="EditProfile" class="profileEditing">
            <form @submit.prevent="updateUserInfos">
                <label for="username">Username</label>
                <input id="username" v-model="username">
                <label for="profileImage">Profile Picture</label>
                <input id="profileImage" v-model="profileImage">
                <button type="submit">Update</button>
            </form>
            <p class="success" v-if="updateDone">Profile updated</p>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { getAuth, onAuthStateChanged, updateProfile, updateEmail, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, updateDoc, doc, where, collection, query, getDocs } from 'firebase/firestore';
import  UserService  from '../composables/UserComposable.js'
export default {
    setup() {
        const updateDone = ref(false)
    
        const auth = getAuth()
        const db = getFirestore()
        
        const username = ref('')
        const profileImage = ref('')
        const campfireID = ref('')

        const EditProfile = ref(false)

        const { getOneUser, getAllUsers, getUsersWhere, getActualUser } = UserService();

        let actualUser;
        let firebaseUser;
        
        onMounted( () => {

            auth.onAuthStateChanged(async function(user) {
                if (user) {
                    firebaseUser = user;
                    actualUser = await getActualUser(db, user.uid)
                    username.value = actualUser.name
                    campfireID.value = actualUser.campfireID
                    profileImage.value = actualUser.profilePicture
                }
            })
        })

        const openSettings = () => {
            EditProfile.value = !EditProfile.value
        }

        const updateUserInfos = () => {
                updateProfile(firebaseUser, {
                    displayName: username.value,
                    photoURL: profileImage.value,
                })  
                updateDoc(doc(db, "users", campfireID.value), {
                    name: username.value,
                    profilePicture: profileImage.value,
                })
                    
            updateDone.value = true
            setTimeout(() => {
                updateDone.value = false
            }, 1000);
        }

        const signOut = () => {
            auth.signOut();
            router.push('/')
        }

        return {
            signOut, updateDone, updateUserInfos, campfireID, EditProfile, openSettings, actualUser, profileImage, username
        }
    }
}
</script>