<template>

</template>

<script>
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDoc, doc, getFirestore } from 'firebase/firestore';
import { onMounted } from 'vue';
import UserService from '../composables/UserComposable'
import ChannelService from '../composables/ChannelComposable'
import MessageService from '../composables/MessagesComposable'
export default {
    setup() {

        const auth = getAuth();
        const db = getFirestore();

        const { getOneUser, getAllUsers, getUsersWhere, getActualUser } = UserService();
        const { getOneChannel, getAllChannels, getChannelsWhere } = ChannelService();
        const { getOneMessage, getAllMessages, getMessagesWhere } = MessageService();

        onMounted(async () => {
            auth.onAuthStateChanged(async function (user) {
                if (user) {
                    
                    let actualUserUid = user.uid;
                    let actualUser = await getActualUser(db, actualUserUid)
                    let oneUser = await getOneUser(db, actualUser.uid);
                    let oneChannel = await getOneChannel(db, "KfEUZCjgSJmW7BP0bL29")
                    let oneMessage = await getOneMessage(db, "300059e3-1e88-6a7f-af02-6261b077da97")
                   
                    let allUsers = await getAllUsers(db);
                    let allChannels = await getAllChannels(db);
                    let allMessages = await getAllMessages(db);

                    let usersWhere = await getUsersWhere(db, "status", "connected");
                    let channelsWhere = await getChannelsWhere(db, "name", "Media");
                    let messagesWhere = await getMessagesWhere(db, "authorID", actualUser.uid);
                }
            })


        
        })

    }
}
</script>