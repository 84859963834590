<template>
    <div class="privateMessages">
        <router-link v-for="privateMessage in privateMessages" :to="'/channel/' + privateMessage.campfireID">
        <!-- {{privateMessage.data}} -->
        <div :class="privateMessage.data.status" class="privateMessages_pfp" :style="{
            backgroundImage: 'url(' + privateMessage.data.profilePicture + ')',
            backgroundRepeat: 'no-repat',
            backgroundSize: 'cover',
            backgroundBlendMode: 'multiply',
            backgroundPosition: 'center'
            }"></div>
            
            <span class="privateMessages_username">
                {{privateMessage.data.name}}
            </span>
            
        </router-link>
        
    </div>
</template>

<script>
import { getFirestore, doc, getDocs, collection, query } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { ref, onMounted } from 'vue'
import ChannelService from '../composables/ChannelComposable.js'
import UserService from '../composables/UserComposable.js'

export default {
    setup() {
        const auth = getAuth()
        const privateMessages = ref([])
        const db = getFirestore()
        let actualUser;
        const pfp = ref()
        
        const { getAllChannels } = ChannelService()
        const { getActualUser, getOneUser } = UserService();

        onMounted(() => {
             auth.onAuthStateChanged(async function(user) {
                
                if(user) {
                    let tempPrivateMessages = []
                    actualUser = await getActualUser(db, user.uid)
                    let allChannels = await getAllChannels(db)
                    for (let i = 0; i < allChannels.length; i++) {
                        if (allChannels[i].members) {                            
                            if(allChannels[i].members.includes(actualUser.campfireID)) {
                                tempPrivateMessages.push(allChannels[i])

                            }
                        }
                     }
                        tempPrivateMessages.sort((a, b) => (a.latestMessage < b.latestMessage) ? 1 : -1)
                    for (let j = 0; j < tempPrivateMessages?.length; j++) {
                        let getUserDM = []
                        getUserDM = tempPrivateMessages[j].members
                        getUserDM.splice(getUserDM.indexOf(actualUser.campfireID), 1)
                        getUserDM = getUserDM.toString()
                        let DMInfos = await getOneUser(db, getUserDM)
                        tempPrivateMessages.data = DMInfos    
                        privateMessages.value.push(tempPrivateMessages[j])
                        privateMessages.value[j].data =  DMInfos
                       
                                  
                    }
                 } 
            })


        })

        return { privateMessages, pfp }

    }
}
</script>