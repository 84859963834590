<template>
    <div>
        <UserUpdate />
    </div>
</template>

<script>
import UserUpdate from '../components/UserUpdate'
export default {
    components: {
        UserUpdate
    },
    setup() {

    }
}
</script>