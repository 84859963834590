<template>
  <div class="app" id="monture"> 
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <div v-if="isLoggedIn" class="router">
      <ChannelList />
    </div>

    <div class="vue" id="vue">
      <router-view/>
    </div>
    <ProfileArea v-if="isLoggedIn" :username="username" :userPfp="userPfp"></ProfileArea>
  </div>
</template>

<style lang="scss">
@import 'assets/scss/main.scss';

</style>

<script>
import ChannelList from './components/ChannelList.vue'
import ProfileArea from './components/ProfileArea.vue';
// import SocketioService from './services/socketio.service.js';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore , doc, getDocs, getDoc, updateDoc, collection, query, where, orderBy, deleteDoc } from 'firebase/firestore'
import { useRouter } from 'vue-router';


export default {
  name: 'App',
  components: {
    ProfileArea,
    ChannelList
  },
  setup() {
   // SocketioService.setupSocketConnection();
    const isLoggedIn = ref(false);
    const username = ref('')
    const userPfp = ref('')
    const currentPath = ref('')
    onMounted(() => {

      const router = useRouter();
      const auth = getAuth();
      const db = getFirestore();
      const UserRef = collection(db, "users");
      const uid = ref("")

      // currentPath.value = '/maintenance'
      // console.log(currentPath.value)
      // router.push('/maintenance')

      let thisUser = [];

      auth.onAuthStateChanged(function(user) {
        if (user) {
          
          uid.value = user.uid
          const UserRef = collection(db, "users")
          const q = query(UserRef, where('uid', '==', uid.value))
          const userInfos = ref()

          getDocs(q)
              .then((response) => {
                  userInfos.value = response.docs.map((doc, id) => {
                      return doc.data();
                  })
                  username.value = userInfos.value[0].name
                  userPfp.value = userInfos.value[0].profilePicture
              })
          isLoggedIn.value = true;
        } else {
          isLoggedIn.value = false;
          if (router.currentRoute.value.path != '/register') {
             router.push('/login')
          }
        }
      
      })


    })
      const signOut = () => {
        auth.signOut();
        router.push('/')
      }


    onBeforeUnmount(() => {
      //SocketioService.disconnect();
    })

    return { username, userPfp, isLoggedIn, signOut, currentPath }
  },


}
</script>