import { getDoc, doc, getDocs, collection, where, query, orderBy } from 'firebase/firestore';

const MessageService = () => {

    const getOneMessage = async (db, data) => {
        const docRef = doc(db, "messages", data);
        const docSnap = await getDoc(docRef);
    
        if(docSnap.exists()) {
            //console.log("one message: ", docSnap.data());
        } else {
            console.log("No such document!");
        }
    
        return docSnap.data();
    }

    const getAllMessages = async (db) => {
        const querySnapshot = await getDocs(collection(db, "messages"))
        let messages = [];
        querySnapshot.forEach((doc) => {
            messages.push(doc.data());
        })
        //console.log("all Messages: ", messages)
        return messages;
    }


    const getMessagesWhere = async (db, at, value) => {
        const q = query(collection(db, "messages"), where(at, "==", value), orderBy('sentAt', 'asc'));
        const querySnapshot = await getDocs(q);
        let messagesWhere = [];
        querySnapshot.forEach((doc) => {
            messagesWhere.push(doc.data());
        })
        //console.log(`Messages where (${at} == ${value}) :`, messagesWhere)
        return messagesWhere;
    }


    return {
        getOneMessage,
        getAllMessages,
        getMessagesWhere

    }
}
    
export default MessageService 