<template>

    <div v-if="invitePopup" class="invitePopup">
        <span class="icon-cross close" @click="showInvitePopup()"></span>
        <input id="inviteID" type="text" :value="'https://campfire.corentinmace.fr/invite/' + serverInfos.inviteID" disabled>
        <button @click="copyInviteURL(serverInfos.inviteID)" type="button">Copy</button>
        {{copyInviteStatus}}
    </div>
    <div class="chat"> 
        <div class="channelInfo" v-if="channelName" :class="type">
            <div>
                <p>{{channelName}}</p>    
            </div>
            <span v-if="dm_status" :class="status" class="channelInfo_status"></span>
        </div>
        <div v-if="type == 'server'" class="server-channels">
            <div @click="showServerMenu()" class="serverInfo" v-if="type == 'server' && serverInfos">
                <p>
                    {{serverInfos.name}}
                </p>
                <span class="icon-circle-down serverInfo_icon"></span>
                <div v-if="serverMenu" class="serverInfo_menu">
                    <router-link v-if="serverInfos.owner == actualUser.campfireID" class="serverInfo_menu-item" :to="'/server-settings/' + serverInfos.campfireID">
                    Settings
                    <div class="icon-cogs"></div>
                    </router-link>
                    <span class="serverInfo_menu-item" @click="showInvitePopup()">
                        <p>Invite Friends</p>
                        <div class="icon-user-plus"></div>
                    </span>
                    <span class="serverInfo_menu-item danger" @click="leaveServer(serverInfos.campfireID)" v-if="serverInfos.owner !== actualUser.campfireID">
                        <p>Leave Server</p>
                        <div class="icon-exit"></div>
                    </span>
                    

                </div>
            </div>
            <div>
                <div class="channel-list">
                    <router-link class="channel-list_item" v-for="channel in serverChannels" :to="`/channel/${serverInfos.campfireID}/${channel.campfireID}`"># {{channel.name}}</router-link>
                </div>    
            </div>
        </div>
        <div :class="type" class="messages" id="messages">
            <div class="message" id="message" v-for="data, i in messages"  @contextmenu.prevent="shiftShowBin(data.campfireID)">
                <div v-if="data.authorID == actualUser.campfireID && showBin == data.campfireID" @click="confirmDelete(data.campfireID)" class="icon-bin2 remove"></div>
                    <div class="profileInfo" v-if="showProfileInfo == data.campfireID">
                        <div @click="hideProfileInfo" class="icon-cross close"></div>
                        <div class="profileInfo_top">                
                            <div class="profileInfo_pfp" :style="{
                            backgroundImage: 'url(' + data.userPfp +')',
                            backgroundRepeat: 'no-repat',
                            backgroundSize: 'cover',
                            backgroundBlendMode: 'mdivtiply',
                            backgroundPosition: 'center'
                            }">
                            <span class="status" :class="data.status"></span>
                            </div>
                            <div>                   
                            <p class="profileInfo_username">{{data.username}}</p>
                            <p class="profileInfo_campfireID">Campfire-ID : {{data.authorID}}</p>
                            </div>
                        </div>
                        <div>
                            <button v-if="!actualUser.friendList.includes(data.authorID) && (actualUser.campfireID != data.authorID)" @click="sendFriendRequest(data.authorID, data.username)">Send friend request</button>
                        </div>
                        <p class="requestStatus" :class="RequestClass" v-if="RequestStatus">{{ RequestText }}</p>
                    </div>
                
                <div class="message-container">  
                    <div @click="openProfileInfo(data.campfireID)" class="messagePfp" :class="data.status" :style="{
                    backgroundImage: 'url(' + data.userPfp +')',
                    backgroundRepeat: 'no-repat',
                    backgroundSize: 'cover',
                    backgroundBlendMode: 'mdivtiply',
                    backgroundPosition: 'center'
                    }"></div>
                    <div>   
                    <span>
                        <h5 class="messageUsername">{{data.username}}</h5>
                        <div class="sentAt">
                            {{ data.formatedDate}}  
                        </div>
                    </span>        
                    <!-- <p>{{data.message}}</p> -->
                    <Markdown :source="data.message" /> 
                    </div>
                </div>
            </div>
        </div>
        <div class="form-container">
            <form :class="type" id="form" @submit.prevent="sendMessage()">
                <span class="is_typing" v-if="IsTyping">{{UserTyping}} is typing..</span>
                <input @input="isTyping(actualUser.name)" id="input" type="text" autocomplete="off" placeholder="Type your message.." v-model="message">
                <div class="text-format">                    
                    <div @click="formatImage()" class="text-format_item icon-file-picture"></div>
                </div>
                <button id="send" class="icon-compass"></button>
            </form>         
        </div>
    </div>
</template>

<script>
import { getAuth, onAuthStateChanged, updateProfile } from 'firebase/auth'
import { getFirestore , setDoc, getDoc, doc, deleteDoc, updateDoc, getDocs, collection, query, where, orderBy} from 'firebase/firestore'

import { ref, onMounted, onUpdated, watch, inject} from 'vue'
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router'
import { io } from 'socket.io-client';
import Markdown from 'vue3-markdown-it';
import UserService from '../composables/UserComposable.js'
import ServerService from '../composables/ServerComposable.js'
import MessageService from '../composables/MessagesComposable.js'
import ChannelService from '../composables/ChannelComposable.js'
const socket = io('https://microservices-corentinmace.fr');

//const socket = io('http://localhost:3000');

export default {
    components: {
        Markdown
    }, 
    setup() {
        const route = useRoute()
        const router = useRouter()
        const type = ref("")
        const roomID = ref("")

        const message = ref("")
        const messages = ref([])

        const invitePopup = ref(false)
        const copyInviteStatus = ref()

        const username = ref("")
        const userPfp = ref("")
        const UserCampfireID = ref("")
        const profileImage = ref('')
        const status = ref('')
        const userFriendList = ref([])
        const showBin = ref('')

        const channelName = ref("")
        const ChannelCampfireID = ref("")

        const isMounted = ref(false)

        const showProfileInfo = ref(false)

        const RequestStatus = ref(false)
        const RequestText = ref('')
        const RequestClass = ref('')

        const dm_status = ref(false)
        const uid = ref('')
        const auth = getAuth()
        const db = getFirestore();
        const UserRef = collection(db, "users")
        const actualUser = ref()

        const serverInfos = ref()
        const serverChannels = ref([])

        const IsTyping = ref(false) 
        const UserTyping = ref('')

        const serverMenu = ref(false)

        const { getOneUser, getAllUsers, getUsersWhere, getActualUser, AddToFriendReq } = UserService();
        const { getAllMessages, getMessagesWhere } = MessageService();
        const { getOneChannel } = ChannelService();
        const { getOneServer } = ServerService();

    

        onBeforeRouteUpdate((to, from) => {
            messages.value = []
            
            status.value = ''
            dm_status.value = false
        })

        onMounted(() => {
            auth.onAuthStateChanged(async function(user) {
                if(user) {
                    actualUser.value = await getActualUser(db, user.uid)
                    retrieveData()
                }
            })
        })

        const leaveServer = async (serverID) => {
            //console.log(serverID)
            let server = await getOneServer(db, serverID)
            let members = server.members
            let userIndex = members.indexOf(actualUser.value.campfireID)
            members.splice(userIndex, 1)

            updateDoc(doc(db, "servers", serverID), {
                members: members
            })
            
            router.push('/')
        }


        const showInvitePopup = () => {
            invitePopup.value = !invitePopup.value
        }

        const copyInviteURL = (inviteID) => {
          navigator.permissions.query({name: "clipboard-write"}).then(result => {
                if (result.state == "granted" || result.state == "prompt") {
                    let newClip = 'https://campfire.corentinmace.fr/invite/' + inviteID
                    navigator.clipboard.writeText(newClip).then(function() {
                        console.log("copied to clip board : ", newClip)
                        copyInviteStatus.value = 'Invite URL has been copied to your clipboard'
                        setTimeout(() => {
                            copyInviteStatus.value = ''
                        }, 3000);
                    }, function() {
                        /* clipboard write failed */
                        copyInviteStatus.value = 'Failed to copy, try to copy it manually'
                        setTimeout(() => {
                            copyInviteStatus.value = ''
                        }, 3000);
                    });
                }
            });
        }

        const showServerMenu = () => {
            serverMenu.value = !serverMenu.value
        }

        const confirmDelete = async (messageID) => {
            await deleteDoc(doc(db, "messages", messageID))
            showBin.value = ''
            let messageIndex;
            let i = 0;
            for (const message of messages.value) {
                if(message.campfireID == messageID) {
                    messageIndex = i
                }
                i++
            }
            messages.value.splice(messageIndex, 1)

            socket.emit('messageDelete', route.params.id, messages.value)
        }

        const shiftShowBin = (messageID) => {
            let messageIndex;
            if (showBin.value == messageID) {
                showBin.value = ''
            } else {
                showBin.value = messageID
            }
        } 

        const isTyping = (user) => {
            socket.emit('isTyping', route.params.id, user)
        }
        
        const formatImage = () => {
            if (message.value == '') {
                message.value = '![](your image link here)'
            } else {
                let formatedMessage = []
                   let words = message.value.split(' ')
                   for (const word of words) {
                       if(word.startsWith('http') || word.startsWith('https')) {
                           formatedMessage.push(`![](${word})`)
                       } else {
                           formatedMessage.push(word)
                       }
                       message.value = formatedMessage.join(' ')
                   }
            }
        }

        const openProfileInfo = (id) => {
            if(showProfileInfo.value == id) {
                showProfileInfo.value = ''
            } else {
                showProfileInfo.value = id
            }
        }

        const hideProfileInfo = () => {
            showProfileInfo.value = false
        }

        const scrollToLatest = () => {
            if(messages.value != 0) {
                document.getElementsByClassName('message')[document.getElementsByClassName('message').length-1].scrollIntoView()
            }
        } 

        const retrieveData = async () => {
            if(route.params.server) {
                type.value = "server"
                socket.emit('room', route.params.id)
                roomID.value = route.params.id
                status.value = ''
                dm_status.value = false
                serverInfos.value = await getOneServer(db, route.params.server)
                serverChannels.value = []
                for (const channel of serverInfos.value.channels) { 
                    let chan = await getOneChannel(db, channel)
                    serverChannels.value.push(chan)  
                }

            } else if(route.params.id) {
                type.value = "dm"
                socket.emit('room', route.params.id)
                roomID.value = route.params.id
            }

            let channelInfos = await getOneChannel(db, route.params.id)
            if(channelInfos.name == '') {
                channelName.value = 'DM'
                channelInfos.members.splice(channelInfos.members.indexOf(actualUser.campfireID), 1)
                channelInfos.members = channelInfos.members.toString()
                let DMInfos = await getOneUser(db, channelInfos.members)
                channelName.value = DMInfos.name
                status.value = DMInfos.status
                dm_status.value = true

            } else {
                channelName.value = channelInfos.name                
            }

            messages.value = await getMessagesWhere(db, 'sentTo', route.params.id)
           
            for (let i = 0; i < messages.value.length; i++) {
                const AuthorQuery = query(UserRef, where('campfireID', '==', messages.value[i].authorID))
                const AuthorInfos = ref()
                getDocs(AuthorQuery)

                    .then((response) => {
                        AuthorInfos.value = response.docs.map((doc, id) => {
                            return doc.data()
                        })
                        let formatedDate = new Date(messages.value[i].sentAt.seconds*1000)
                        
                        messages.value[i].formatedDate =  formatedDate.toString().split(' ').splice(4, 1).join(' ')
                        messages.value[i].username =  AuthorInfos.value[0].name
                        messages.value[i].userPfp = AuthorInfos.value[0].profilePicture
                        messages.value[i].status = AuthorInfos.value[0].status
                        messages.value[i].authorUID = AuthorInfos.value[0].uid
                    })
            }
        
            setTimeout(() => {
                if(messages.value != 0) {
                   document.getElementsByClassName('message')[document.getElementsByClassName('message').length-1].scrollIntoView()
                }  
            }, 500);

        }

        const sendFriendRequest = async (UserID, UserName) => {
            let friendData = await getOneUser(db, UserID)
            let friendRequests = []
            friendRequests = friendData.friendRequests

            if (friendRequests.includes(actualUser.value.campfireID)) {
                RequestStatus.value = true  
                RequestText.value = 'Request already sent..'
                RequestClass.value = 'danger'
            } else {
                AddToFriendReq(db, UserID, actualUser.value.campfireID)
                RequestStatus.value = true  
                RequestText.value = 'Request sent !'
                RequestClass.value = 'success'
            }

            setTimeout(() => {
                RequestStatus.value = false
            }, 3000);
        }

        const MessageUUID = () => { // Public Domain/MIT
                let d = new Date().getTime();//Timestamp
                let d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
                return '30xxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    let r = Math.random() * 16;//random number between 0 and 16
                    if (d > 0) {//Use timestamp until depleted
                        r = (d + r) % 16 | 0;
                        d = Math.floor(d / 16);
                    } else {//Use microseconds since page-load if supported
                        r = (d2 + r) % 16 | 0;
                        d2 = Math.floor(d2 / 16);
                    }
                    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
            }

        const sendMessage = () => {
            let formatedMessage = []
            let words = message.value.split(' ')
            for (const word of words) {
                if(word.startsWith('http') || word.startsWith('https')) {
                    formatedMessage.push(`[${word}](${word})`)
                } else {
                    formatedMessage.push(word)
                }
                message.value = formatedMessage.join(' ')
            }
            let sentAt = new Date()
            let sentAtSocket = new Date(sentAt.getTime())
            let uid = MessageUUID();
            const data = {
                campfireID: uid,
                message: message.value,
                username: actualUser.value.name,
                userPfp: actualUser.value.profilePicture,
                status: actualUser.value.status,
                formatedDate: sentAtSocket.toString().split(' ').splice(4, 1).join(' '),
                sentTo: route.params.id,
                authorID: actualUser.value.campfireID
            }
            if(message.value) {
                setDoc(doc(db, "messages", uid), {
                    campfireID: uid,
                    authorID: actualUser.value.campfireID,
                    message: message.value,
                    sentAt: sentAt,
                    sentTo: route.params.id,
                })
                socket.emit('message', data)
                updateDoc(doc(db, "channels", route.params.id), {
                    latestMessage: sentAt
                })
            }
            message.value = ''
        }

        socket.on("message", (data) => {
            messages.value.push(data)
            setTimeout(() => {
                if(messages.value != 0) {
                   document.getElementsByClassName('message')[document.getElementsByClassName('message').length-1].scrollIntoView()
                }  
            }, 100);
            //scrollToLatest()
        })

        socket.on("isTyping", (user) => {
            if (actualUser.value.name !== user) {     
                IsTyping.value = true
                UserTyping.value = user
                setTimeout(() => {
                    IsTyping.value = false
                }, 5000);
            }
        })

        socket.on('messageDelete', (newMessages) => {
            messages.value = [] 
            messages.value = newMessages
        })

        watch(
            () => route.params.id,
            async newId => {
                await retrieveData()
            }
        )

        return { 
            leaveServer, 
            showServerMenu,
            serverMenu, 
            copyInviteStatus, 
            invitePopup, 
            showInvitePopup, 
            copyInviteURL, 
            serverChannels, 
            serverInfos, 
            confirmDelete, 
            shiftShowBin, 
            showBin, 
            UserTyping, 
            IsTyping, 
            isTyping, 
            formatImage, 
            dm_status, 
            status, 
            actualUser, 
            scrollToLatest, 
            userFriendList, 
            RequestText, 
            RequestClass, 
            RequestStatus, 
            sendFriendRequest, 
            hideProfileInfo, 
            showProfileInfo, 
            roomID,
            route, 
            openProfileInfo, 
            type, 
            message, 
            messages, 
            sendMessage, 
            channelName, 
            username
        }
    } 
}
</script>