<template>
  <div>
      <FriendList />
  </div>
</template>

<script>
import FriendList from "../components/FriendList.vue"

export default {
  name: 'Home',
  components: {
      FriendList
  }
}
</script>
