<template>
        <div class="login">
            <div class="login_container">
                <CampfireLogo />
                <form  @submit.prevent="register">          
                  <label class="" for="password">Username</label>
                  <input v-model="nickname" class="" required id="nickname" type="text">
                  <label class="" for="username">Email</label>
                  <input v-model="email" class="" required id="username" type="email">
                  <label class="" for="password">Password</label>
                  <input v-model="password" class="" required id="password" type="password">
                  <!-- <p class="text-red-500 text-xs italic">Please choose a password.</p> -->
                  <button class="" type="submit">Register</button>
                  <!-- <a class="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="#">
                      Forgot Password?
                  </a> -->
                </form>
                <p class="errorMessage" v-if="errMsg">{{ errMsg }}</p>
                <router-link to="/login">Already have an account ? Click here</router-link>
            </div>
        </div>  
</template>

<script>
  import { ref } from 'vue'
  import { doc, setDoc, addDoc, updateDoc, getFirestore, collection } from 'firebase/firestore'
  import { getAuth, createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
  import { useRouter } from 'vue-router' // import router
  import CampfireLogo from '../components/CampfireLogo'

  export default {
    components: {
        CampfireLogo
    }, setup() {
      
      const db = getFirestore();
      const auth = getAuth()
      const email = ref('')
      const password = ref('')
      const nickname = ref('')
      const errMsg = ref('')
      const router = useRouter() // get a reference to our vue router
    
    const UserUUID = () => { // Public Domain/MIT
            let d = new Date().getTime();//Timestamp
            let d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
            return '10xxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                let r = Math.random() * 16;//random number between 0 and 16
                if (d > 0) {//Use timestamp until depleted
                    r = (d + r) % 16 | 0;
                    d = Math.floor(d / 16);
                } else {//Use microseconds since page-load if supported
                    r = (d2 + r) % 16 | 0;
                    d2 = Math.floor(d2 / 16);
                }
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
        }
    
      const register = () => {
        if(nickname.value == '') {
            alert('please enter a nickname')
        } else {
          createUserWithEmailAndPassword(auth, email.value, password.value) // need .value because ref()
            .then((data) => {
              updateProfile(data.user, {
                displayName: nickname.value,
                photoURL: '/assets/images/global/defaultPfp.jpg' 
              })
              
              let uid = data.user.uid
              let campfireID = UserUUID();
              setDoc(doc(db, "users", campfireID), {
                  uid: uid,
                  campfireID: campfireID,
                  name: nickname.value,
                  email: data.user.email,
                  profilePicture: '/assets/images/global/defaultPfp.jpg',
                  friendList: [],
                  friendRequests: [],
                  status: "connected"
              })
              router.push('/') // redirect to the feed
            })
            .catch(error => {
              console.log(error.code)
              errMsg.value = error.message;
            });
        }
      }
   
      return {
        email, password, nickname, register, errMsg
      }
     }
  }
</script>