<template>
    <div class="profileArea">
        <div class="profileArea_infos">
            <div class="profileArea_infos_goto">
                <router-link to="/settings">View Profile</router-link>
            </div>
            <div class="profileArea_infos_pfp " :style="{
            backgroundImage: 'url(' + actualUser.profilePicture +')',
            backgroundRepeat: 'no-repat',
            backgroundSize: 'cover',
            backgroundBlendMode: 'multiply',
            backgroundPosition: 'center'
            }"></div>
            <div class="profileArea_infos_username">
                {{ actualUser.name }}
            </div>
            <!-- <router-link to="/settings">
                <div class="profileArea_infos_settings icon-cog"></div>
            </router-link>
            <div @click="signOut" class=" profileArea_infos_logout icon-exit"></div> -->

            <span class="status" :class="status"></span>
            <div class="select-status">
                <div class="statuses">
                    <span @click="setStatus('connected')" class="select connected"></span>
                    <span @click="setStatus('not-disturb')" class="select not-disturb"></span>
                    <span @click="setStatus('idle')" class="select idle"></span>
                    <span @click="setStatus('')" class="select"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, toRef, onMounted } from 'vue'
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, updateDoc, doc, where, collection, query, getDocs } from 'firebase/firestore';
import UserService from '../composables/UserComposable.js';

export default {
    name: 'ProfileArea',
    components: {
    },
    props: {
        username: String,
        userPfp: String
    },
    setup(props) {

        let auth = getAuth();

        const db = getFirestore()
        const status = ref("")
        const actualUser = ref('')

        const { getActualUser} = UserService();


        onMounted(() => {
            auth.onAuthStateChanged(async function(user) {
                if (user) {
                    actualUser.value = await getActualUser(db, user.uid)
                    status.value = actualUser.value.status
                }
            })
        })

        const signOut = () => {
            auth.signOut();
            router.push('/')
        }

        const setStatus = (getStatus) => {
            status.value = getStatus
            updateDoc(doc(db, "users", actualUser.value.campfireID), {
                status: getStatus,
            })
        }



        return { status, signOut, setStatus, actualUser, status }
    }
}
</script>