<template>
    <div class="channelInfo" v-if="serverInfos">
        <p class="serverName">{{ name }}</p>
        <span class="icon-cog"></span>
    </div>
    <div v-if="serverInfos" class="serverInfos_config">
        <div class="user-update_banner">
            <div class="user-update_banner_pfp" :style="{
            backgroundImage: 'url(' + icon +')',
            backgroundRepeat: 'no-repat',
            backgroundSize: 'cover',
            backgroundBlendMode: 'multiply',
            backgroundPosition: 'center'
            }"></div>
            <div class="user-update_banner_infos">
                <p class="user-update_banner_username">{{ name }}</p>     
                <p class="user-update_banner_campfireID">Campfire-ID : {{ serverInfos.campfireID }}</p> 
            </div>
        </div>
        <div class="serverInfos_config-updates">
        <form>
            <label for="server-name">Server Name</label>
            <input id="server-icon" type="text" v-model="name">
            <label for="server-icon">Server Icon</label>
            <input id="server-icon" type="text" v-model="icon">
            <label for="welcome-channel">Welcome Channel</label>
            <select name="" id="welcome-channel" v-model="newWelcomeChannel">
                <option :value="serverInfos.welcomeChannel" disabled="disabled" selected="selected">{{welcomeChannel}}</option>
                <option v-for="channel in channels" :value="channel[0].campfireID">{{channel[0].name}}</option>
            </select>
            <label for="description">Description</label>
            <textarea name="" id="description" cols="30" rows="3" v-model="description">{{description}}</textarea>
            <button @click.prevent="updateServerInfo(serverInfos.campfireID)">Update Server Infos</button>
        </form>
    
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import { getAuth, onAuthStateChanged, updateProfile } from 'firebase/auth'
import { getFirestore, updateDoc, doc  } from 'firebase/firestore';
import UserService from '../composables/UserComposable.js'
import ServerService from '../composables/ServerComposable.js'
import ChannelService from '../composables/ChannelComposable';
import router from '../router';

export default {
    setup() {
        const route = useRoute();
        const routeId = ref()
        let auth = getAuth()
        const db = getFirestore()
        const actualUser = ref()
        const serverInfos = ref()
        const channels = ref([])
        const name = ref()
        const icon = ref()
        const description = ref()
        const newWelcomeChannel = ref()
        const welcomeChannel = ref()
        const { getActualUser} = UserService();
        const { getOneServer } = ServerService();
        const { getChannelsWhere, getOneChannel } = ChannelService();

        onMounted(() => {
            auth.onAuthStateChanged(async function(user) {
                if(user) {
                    actualUser.value = await getActualUser(db, user.uid)
                    retrieveData()
                }
            })
        })

        const updateServerInfo = async (serverID) => {     
            if(newWelcomeChannel.value == undefined) {
                newWelcomeChannel.value = serverInfos.value.welcomeChannel
            }   
            updateDoc(doc(db, "servers", serverID), {
                name: name.value,
                icon: icon.value,
                description: description.value,
                welcomeChannel: newWelcomeChannel.value
            })
        }

        const retrieveData = async () => {
            serverInfos.value = await getOneServer(db, route.params.id)
            if(serverInfos.value.owner != actualUser.value.campfireID) {
                router.push('/')
            }

            name.value = serverInfos.value.name
            icon.value = serverInfos.value.icon
            description.value = serverInfos.value.description
            
            for (const channel of serverInfos.value.channels) {
                let chan = await getChannelsWhere(db, 'campfireID', channel)
                channels.value.push(chan)
            }
            let tempChan = await getOneChannel(db, serverInfos.value.welcomeChannel)
            welcomeChannel.value = tempChan.name

        }

        routeId.value = route.params.id

        return {
            serverInfos,
            welcomeChannel,
            channels,
            name,
            description,
            icon,
            newWelcomeChannel,
            updateServerInfo
        }
    }
}
</script>