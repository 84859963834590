import { getDoc, doc, getDocs, collection, where, query } from 'firebase/firestore';

const ChannelService = () => {

    const getOneChannel = async (db, data) => {
        const docRef = doc(db, "channels", data);
        const docSnap = await getDoc(docRef);
    
        if(docSnap.exists()) {
            //console.log("one channel: ", docSnap.data());
        } else {
            console.log("No such document!");
        }
    
        return docSnap.data();
    }

    const getAllChannels = async (db) => {
        const querySnapshot = await getDocs(collection(db, "channels"))
        let channels = [];
        querySnapshot.forEach((doc) => {
            channels.push(doc.data());
        })
        //console.log("all channels: ", channels)
        return channels;
    }

    const getChannelsWhere = async (db, at, value) => {
        const q = query(collection(db, "channels"), where(at, "==", value));
        const querySnapshot = await getDocs(q);
        let channelsWhere = [];
        querySnapshot.forEach((doc) => {
            channelsWhere.push(doc.data());
        })
        //console.log(`channels where (${at} == ${value}) :`, channelsWhere)
        return channelsWhere;
    }


    return {
        getOneChannel,
        getAllChannels,
        getChannelsWhere

    }
}
    
export default ChannelService 