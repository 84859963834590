<template>
    <div v-if="isInvite && serverInfo">
        <div class="invite-container">
            <div class="invite-infos">
                <span>You have been invited to</span>
                <div class="invite-infos-card">
                    <div class="invite-infos-card-top">
                        <img class="invite-infos-card-element invite-icon" :src="serverInfo.icon" alt="">
                        <span>
                            <p class="invite-infos-card-element">{{ serverInfo.name }}</p>
                            <p class="invite-infos-card-element members">{{ serverInfo.members.length }} member(s)</p>
                        </span>
                    </div>
                    <div class="invite-infos-card-bottom">
                        <button class="invite-infos-button" @click="joinServer(serverInfo.campfireID, serverInfo.members, serverInfo.welcomeChannel)">Join</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="invite-container">
            <div class="invite-infos">
                <div class="invite-infos-card">
                    <span class="invite-infos-card-title">Create a server</span>
                    <input placeholder="Your server name" v-model="serverName">
                    <button class="invite-infos-button" type="" @click="createServer()">Create</button>
                    <p v-if="errorName" class="danger">No name entered</p>
                    <div class="invite-or">    
                        <span class="separator"></span>
                        <span>OR</span>
                        <span class="separator"></span>
                    </div>
                    <span class="invite-infos-card-title">Join an existing server</span>
                    <input placeholder="Server invite ID" v-model="serverInviteId">
                    <button class="invite-infos-button" type="" @click="joinServerViaID()">Join</button>
                    <p v-if="errorID" class="danger">No ID given or wrong ID</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getFirestore, updateDoc, doc, setDoc } from 'firebase/firestore'
import { useRoute, useRouter } from 'vue-router'
import ServerService from '../composables/ServerComposable.js'
import UserService from '../composables/UserComposable.js'
export default {
    setup() {

        const auth = getAuth()
        const { getServersWhere } = ServerService();
        const { getActualUser } = UserService();

        const db = getFirestore();

        const route = useRoute()
        const router = useRouter()
        const isInvite = ref(false)
        const actualUser = ref()
        const serverInfo = ref()

        const serverInviteId = ref()
        const serverName = ref()
        const errorName = ref(false)

        const errorID = ref(false)

        onMounted(() => {
            auth.onAuthStateChanged(async function(user) {
                if(user) {
                    actualUser.value = await getActualUser(db, user.uid)
                }
            })

            if (route.params.id) {
                isInvite.value = true
                retrieveServerInfo()
            } else {
                isInvite.value = false
            }
        })

        const ServerUUID = () => { // Public Domain/MIT
            let d = new Date().getTime();//Timestamp
            let d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
            return '50xxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                let r = Math.random() * 16;//random number between 0 and 16
                if (d > 0) {//Use timestamp until depleted
                    r = (d + r) % 16 | 0;
                    d = Math.floor(d / 16);
                } else {//Use microseconds since page-load if supported
                    r = (d2 + r) % 16 | 0;
                    d2 = Math.floor(d2 / 16);
                }
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
        }

         const ChannelUUID = () => { // Public Domain/MIT
            let d = new Date().getTime();//Timestamp
            let d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
            return '20xxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                let r = Math.random() * 16;//random number between 0 and 16
                if (d > 0) {//Use timestamp until depleted
                    r = (d + r) % 16 | 0;
                    d = Math.floor(d / 16);
                } else {//Use microseconds since page-load if supported
                    r = (d2 + r) % 16 | 0;
                    d2 = Math.floor(d2 / 16);
                }
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
        }



        const retrieveServerInfo = async () => {
            let server = await getServersWhere(db, 'inviteID', route.params.id)
            serverInfo.value = server[0]
        }

        const joinServer = (serverId, members, welcomeChannel) => {
            let serverMembers = []
            serverMembers = members
            if(members.includes(actualUser.value.campfireID)) {
                    router.push(`/channel/${serverId}/${welcomeChannel}`)
            } else {
                serverMembers.push(actualUser.value.campfireID)
                updateDoc(doc(db, "servers", serverId), {
                    members: serverMembers
                })
                router.push(`/channel/${serverId}/${welcomeChannel}`)
            }
        }

        const inviteID = (length) => {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }

        const createServer = () => {
            if(serverName.value) {
                let ChannelCampfireID = ChannelUUID()
                let ServerCampfireID = ServerUUID()
                let channels = [ChannelCampfireID]
                let members = [actualUser.value.campfireID]
                let now = new Date()
                setDoc(doc(db, "channels", ChannelCampfireID), {
                    campfireID: ChannelCampfireID,
                    description: "",
                    latestMessage: now,
                    members: [],
                    name: "general"
                })
                setDoc(doc(db, "servers", ServerCampfireID), {
                    admins: [],
                    campfireID: ServerCampfireID,
                    channels: channels,
                    description: '',
                    icon: '',
                    inviteID: inviteID(12),
                    members: members,
                    name: serverName.value,
                    owner: actualUser.value.campfireID,
                    roles: [],
                    welcomeChannel: ChannelCampfireID
                })

                router.push(`/channel/${ServerCampfireID}/${ChannelCampfireID}`)
            } else {
                errorName.value = true
                setTimeout(() => {
                    errorName.value = false
                }, 5000);

            }
        }

        const joinServerViaID = async () => {
            if(serverInviteId.value) {
                let server = await getServersWhere(db, 'inviteID', serverInviteId.value)
                if (server.length > 0) {
                    if(server[0].members.includes(actualUser.value.campfireID)) {
                        router.push(`/channel/${server[0].campfireID}/${server[0].welcomeChannel}`)

                    } else {
                        let serverMembers = []
                        serverMembers = server[0].members
                        serverMembers.push(actualUser.value.campfireID)

                        updateDoc(doc(db, "servers", server[0].campfireID), {
                            members: serverMembers
                        })
                        router.push(`/channel/${server[0].campfireID}/${server[0].welcomeChannel}`)
                    }
                } else {
                     errorID.value = true 
                    setTimeout(() => {
                        errorID.value = false
                    }, 5000);
                }
            } else {
                errorID.value = true 
                setTimeout(() => {
                    errorID.value = false
                }, 5000);
            }
        }

        return { joinServerViaID, errorID, errorName, isInvite, serverInfo, joinServer, serverName, createServer, serverInviteId }
    }
}
</script>