import { getDoc, doc, getDocs, collection, where, query, updateDoc } from 'firebase/firestore';

const UserService = () => {

    
    const getOneUser = async (db, data) => {
        const docRef = doc(db, "users", data);
        const docSnap = await getDoc(docRef);
        
        if(docSnap.exists()) {
            //console.log("one user: ", docSnap.data());
        } else {
            console.log("No such document!");
        }
        
        return docSnap.data();
    }
    
    const getAllUsers = async (db) => {
        const querySnapshot = await getDocs(collection(db, "users"))
        let users = [];
        querySnapshot.forEach((doc) => {
            users.push(doc.data());
        })
        //console.log("all users: ", users)
        return users;
    }
    
    const getUsersWhere = async (db, at, value) => {
        const q = query(collection(db, "users"), where(at, "==", value));
        const querySnapshot = await getDocs(q);
        let usersWhere = [];
        querySnapshot.forEach((doc) => {
            usersWhere.push(doc.data());
        })
        //console.log(`users where (${at} == ${value}) :`, usersWhere)
        return usersWhere;
    }
    
    const getActualUser = async (db, uid) => {
        let tempUser = await getUsersWhere(db, "uid", uid)
        let actualUser = tempUser[0]
        return actualUser;
    }

    const AddToFriendList = async (db, toUpdate, toAdd) => {
        let newFriendList = []
        let user = await getOneUser(db, toUpdate)
        newFriendList = user.friendList
        newFriendList.push(toAdd)
        updateDoc(doc(db, "users", toUpdate), {
            friendList: newFriendList
        })
    }

    const RemoveFromFriendList = async (db, toUpdate, toRemove) => {
        let newFriendList = []
        let user = await getOneUser(db, toUpdate)
        newFriendList = user.friendList
        newFriendList.splice(newFriendList.indexOf(toRemove), 1) 

        updateDoc(doc(db, "users", toUpdate), {
            friendList: newFriendList
        })
    }

    const AddToFriendReq = async (db, toUpdate, toAdd) => {
        let newFriendReq = []
        
        let user = await getOneUser(db, toUpdate)
        newFriendReq = user.friendRequests
        newFriendReq.push(toAdd)
        
        updateDoc(doc(db, "users", toUpdate), {
            friendRequests: newFriendReq
        })
    }

    const RemoveFromFriendReq = async (db, toUpdate, toRemove) => {
        let newFriendReq = []
        let user = await getOneUser(db, toUpdate)
        newFriendReq = user.friendRequests
        newFriendReq.splice(newFriendReq.indexOf(toRemove), 1) 

        updateDoc(doc(db, "users", toUpdate), {
            friendRequests: newFriendReq
        })
    }
    
    return {
        getOneUser,
        getAllUsers,
        getUsersWhere,
        getActualUser,
        AddToFriendList,
        RemoveFromFriendList,
        AddToFriendReq,
        RemoveFromFriendReq,


    }
}
    
export default UserService 