import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Settings from '../views/Settings.vue'
import Channel from '../views/Channel.vue'
import Test from '../views/Test.vue'
import Maintenance from '../views/Maintenance.vue'
import Invite from '../views/Invite.vue'
import ServerSettings from '../views/ServerSettings.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/channel/:id',
    name: 'Channel',
    component: Channel
  },
  {
    path: '/channel/:server/:id',
    name: 'Server',
    component: Channel
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance
  },
  {
    path: '/invite/:id',
    name: 'InviteID',
    component: Invite
  },
  {
    path: '/invite/',
    name: 'Invite',
    component: Invite
  },
  {
    path: '/server-settings/:id',
    name: 'ServerSettings',
    component: ServerSettings
  } 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
